<template>
  <Dialog
    v-model="showModalTest"
    persistent
    :onReset="cancelPopupTest"
  >
    <div class="flex flex-row">
      <div class="pr-8">
        <img
          src="@/app/ui/assets/images/gamification-rtc-image.png"
          class="max-w-none w-250px"
          alt="gami-logo"
        />
      </div>
      <div class="w-600px pl-8">
        <div class="mb-4 rounded h-16 bg-yellow-lp-400 flex flex-row px-2">
          <img
            src="@/app/ui/assets/svg/warning-circle.svg"
            class="max-w-none w-20px"
            alt="warning"
          />
          <p class="font-normal p-4 mb-6">
            Sebelum melanjutkan Booking, Anda harus masukkan persetujuan terlebih dahulu. Anda cukup mengikuti teks yang sudah tersedia
          </p>
        </div>
        <div class="flex flex-row pb-2">
          <p class="font-semibold">Ketik Kalimat Sesuai Petunjuk</p>
          <span class="text-red-lp-300 font-normal pl-1">*</span>
        </div>
        <div :class="error ? 'box__error' : 'box'">
          <p class="text__p">
            <span v-for="(char, charIndex) in templateWord" :key="charIndex">
              <span v-if="showCursor(charIndex)" id="caret" class="blink"
                >|</span
              >
              <span
                v-if="charIndex === selected"
                :class="error ? 'text__error' : ''"
                >{{ char === " " && error ? "_" : char }}</span
              >
              <span v-else-if="charIndex < selected" class="text__done">{{
                char
              }}</span>
              <span v-else>{{ char }}</span>
            </span>
          </p>
        </div>
        <div v-if="error" class="flex flex-row">
          <img
            src="@/app/ui/assets/svg/exclamation-triangle.svg"
            class="max-w-none w-20px"
            alt="error"
          />
          <p class="error__message">
            Ketik kalimat sesuai kata, spasi, tanda baca, huruf besar & kecil yang ada di kotak
          </p>
        </div>
        <div class="flex flex-row justify-between">
          <div></div>
          <div class="mt-12">
            <LpButton
              textColor="white"
              title="Lanjutkan"
              fontWeight="semibold"
              @click="submitPopup"
              :disabled="!isDisableButton"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { CargoController } from "@/app/ui/controllers/CargoController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { FlagsGamificationRTC } from "@/feature-flags/flag-gamification-rtc";
import { Vue } from "vue-class-component";

export default class GamificationRTC extends Vue {
  
  error = false;
  selected = 0;
  isDisableButton = false;

  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key === "Backspace") {
        this.error = false;
      }
    });
    window.addEventListener("keypress", e => {
      if (this.templateWord[this.selected] === e.key) {
        if (this.selected > this.templateWord.length - 2) {
          this.isDisableButton = true;
        }
        this.error = false;
        this.selected++;
      } else if (e.key === "Backspace") {
        this.error = false;
      } else {
        this.error = true;
      }
    });
  }

  get progress() {
    return CargoController.progressValue;
  }

  get showModalTest() {
    return CargoController.isShowModalGamification;
  }
  cancelPopupTest() {
    CargoController.setIsModalGamification(false);
    if (this.progress === 0) {
      this.$router.push("/cargo");
    }
    if (this.progress === -1) {
      ReadyToCargoController.setFlowBooking("");
    }
  }
  submitPopup() {
    CargoController.setIsModalGamification(false);
    if (this.progress === -1) {
      CargoController.setProgressValue(0);
      CargoController.setIsFromRTC(true);
    }
  }
  showCursor(charIndex) {
    if (this.error) return charIndex - 1 === this.selected;
    if (!this.error) return charIndex === this.selected;
    return false;
  }

  get templateWord() {
    return FlagsGamificationRTC.feature_gamification_message.getValue();
  }
}
</script>

<style scoped>
.box {
  width: 570px;
  height: 130px;
  padding: 10px;
  border: 1px solid rgb(2, 2, 2);
  border-radius: 6px;
  margin: 0;
}
.box__error {
  width: 570px;
  height: 130px;
  padding: 10px;
  border: 1px solid #e40101;
  border-radius: 6px;
  margin: 0;
}
.text__p {
  font-size: 14px;
  line-height: 1.5;
  padding: 2px;
  color: #adadad;
}
.text__done {
  color: #000000;
}
.text__error {
  color: #e40101;
  /* background-color: #e40101; */
}
#caret.blink {
  animation: blink 1s infinite 0.5s;
  color: #000000;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.error__message {
  color: #e40101;
  font-size: 14px;
  padding: 2px;
}
</style>
